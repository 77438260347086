<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-videosnippets-list')"
        @delete="helperDeleteItem(`/videosnippets/${this.$route.params.id}`, null, 'apps-videosnippets-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <b-row>
      <b-col md="6" class="mb-2">
        <b-card :title="$t('Video Poster')" class="h-100">
          <b-media class="">
            <template #aside>
              <b-avatar
                  class="border"
                  rounded
                  ref="previewPoster"
                  size="8rem"
                  :src="currentItem.poster ? currentItem.poster.url : require('@/assets/images/svg/email.svg')"
              ></b-avatar>
            </template>
            <div class="d-flex flex-wrap">
              <b-button
                  class="ml-1"
                  variant="success"
                  size="sm"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="addImage"
                />
                <span v-if="currentItem.poster && currentItem.poster.url">
                {{ $t('Update') }}
              </span>
                <span v-else>
                {{ $t('Add') }}
              </span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>
              </b-button>
              <b-button
                  v-if="currentItem.poster && currentItem.poster.url"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  @click="deletePosterImage"
              >
                <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
                <feather-icon icon="TrashIcon" class="d-inline d-sm-none"/>
              </b-button>

              <b-modal id="bv-modal-example" hide-footer>

                <div class="d-block text-center">
                  <cropper
                      ref="cropper"
                      v-if="posterImagePreview"
                      class="cropper cropper_custom"
                      :src="posterImagePreview"
                      :default-position="defaultPosition"
                      :default-size="defaultSize"
                      :image-restriction="restrictionType"
                      @change="updateSize"
                      :resize-image="{
                           adjustStencil: true,
                        touch: true,
                        mouse: true,
                      }"
                      :stencil-props="{
                        movable: false,
                        resizable: true,
                        handlers: {
                          eastNorth: true,
			                    north: false,
                          westNorth: true,
                          west: false,
                          westSouth: true,
                          south: false,
                          eastSouth: true,
                          east: false,},
                        lines: {},
                       }"
                      auto-zoom="true"
                      :stencil-size="stencilSize"
                  >


                  </cropper>
                </div>
                <b-row>
                  <b-col class="md-6">
                    <div>
                      <feather-icon @click="move('top')" icon="ArrowUpIcon" class="mt-1 zoom-button" size="22"/>
                      <feather-icon @click="move('left')" icon="ArrowLeftIcon" class="mt-1 ml-1 zoom-button" size="22"/>
                      <feather-icon @click="move('right')" icon="ArrowRightIcon" class="mt-1 ml-1 zoom-button"
                                    size="22"
                      />
                      <feather-icon @click="move('bottom')" icon="ArrowDownIcon" class="mt-1 ml-1 zoom-button"
                                    size="22"
                      />
                    </div>
                  </b-col>
                  <b-col class="md-6 zoom-group d-flex justify-content-end">
                    <feather-icon @click="zoom(1.2)" icon="ZoomInIcon" class=" mt-1 zoom-button mb-2" size="22"/>
                    <feather-icon @click="zoom(0.8)" icon="ZoomOutIcon" class="mt-1 ml-1 mb-2 zoom-button" size="22"/>
                  </b-col>
                </b-row>

                <div>
        <span class="mt-1"
        >{{
            $t('You can zoom in and out by using your mouse wheel. Each scroll, up or down, increases or decreases the zoom factor. Please center your Video-Poster')
          }}</span>
                </div>

                <b-button class="mt-3" block @click="uploadPosterImage">{{ $t('Save') }}</b-button>
              </b-modal>


            </div>
          </b-media>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-card :title="$t('Video')" class="h-100">
          <videosnippet-fileupload v-if="currentItem.id"></videosnippet-fileupload>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-card :title="$t('General informations')">
        <!-- Form -->
        <b-form
            @submit.stop.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="6">
              <div class="mb-2">
                <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
                  {{ $t('Active') }}
                </b-form-checkbox>
              </div>

              <b-form-group :label="$t('Start Date')">
                <validation-provider #default="validationContext" :name="$t('startdate')" rules="required">
                  <b-form-datepicker id="startdate" :state="getValidationState(validationContext)"
                                     :name="$t('startdate')" :placeholder="$t('Choose your start Date')"
                                     v-model="currentItem.startdate"
                                     v-bind="labels[locale] || {}"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- Name -->
              <b-form-group :label="$t('name')">
                <validation-provider #default="{ errors }" :name="$t('Video Nugget Name')" rules="required">
                  <b-form-input id="name" v-model="currentItem.name" autofocus trim placeholder="Video Nugget Name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                      $t(validation.name[0])
                    }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group :label="$t('Short Description')">
                <validation-provider #default="{ errors }" :name="$t('Short Description')" rules="required">
                  <b-form-textarea rows="4" v-model="currentItem.description" :placeholder="$t('Short Description')"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('description')" class="text-danger">{{
                      $t(validation.description[0])
                    }}</small>
                </validation-provider>
                <span>{{ charactersLeft }}</span>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('clients')">
                <b-form-checkbox v-for="option in me.clients" :key="option.id" v-model="currentItem.clients"
                                 :value="option.id" name="clients" class="mb-2"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
    <b-card>
      <div class="d-flex">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                  @click.prevent="onSubmit"
        >
          {{ $t('Update Video Nugget') }}
        </b-button>
      </div>
    </b-card>
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BLink,
  BMedia,
  BModal,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import FileInput from './FileInput'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import VideosnippetFileupload from './forms/VideosnippetFileupload'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de.js'
import { max } from 'vee-validate/dist/rules'
import { setDatePickerTranslation } from '@core/mixins/datePickerLocales'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import BCardActions from '@core/components/b-card-actions/BCardActions'

extend('max', max)
export default {
  components: {
    Cropper,
    BAvatar,
    FileInput,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormTextarea,
    quillEditor,
    flatPickr,
    BFormDatepicker,
    BMedia,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VideosnippetFileupload,
    BFormFile,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  mixins: [setDatePickerTranslation],
  data() {
    return {
      posterImagePreview: null,
      imageName: null,
      me: {},
      currentItem: {
        id: '',
        name: '',
        status: '',
        description: '',
        startdate: '',
        clients: []
      },
      files: [],
      loaded: true,
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },
      chunkEnabled: true,
      // 1MB by default
      chunkMinSize: 1,
      chunkMaxActive: 3,
      chunkMaxRetries: 5,
      chunk: {
        action: '/dsadasddssa',
        minSize: this.chunkMinSize * 1048576,
        maxActive: this.chunkMaxActive,
        maxRetries: this.chunkMaxRetries
      },
      config: {
        enableTime: true,
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd.m.Y H:i',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        locale: German, // locale for this instance only

      },
      validation: {},
      validate: false,
      restrictionType: 'none',
      stencilSize: {
        width: process.env.VUE_APP_VIDEO_NUGGET_POSTER_WIDTH,
        height: process.env.VUE_APP_VIDEO_NUGGET_POSTER_HEIGHT
      },
      size: {
        width: null,
        height: null,
      },
    }

  },
  computed: {
    charactersLeft() {
      if (this.currentItem.description) {
        let char = this.currentItem.description.length,
            limit = 300
        return char + ' / ' + limit + ' ' + this.$t('characters remaining')
      }
    }
  },

  setup() {
    const getData = () => {
      videosnippetsData.value = JSON.parse(JSON.stringify(blankvideosnippetsData))
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/videosnippets/${this.$route.params.id}`)
    },
    defaultPosition() {
      return {
        left: 100,
        top: 100,
      }
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 16)
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 16)
      } else if (direction === 'top') {
        this.$refs.cropper.move(0, -this.size.height / 16)
      } else if (direction === 'bottom') {
        this.$refs.cropper.move(0, this.size.height / 16)
      }
    },
    defaultSize() {
      return {
        width: process.env.VUE_APP_EVENT_IMAGE_WIDTH,
        height: process.env.VUE_APP_EVENT_IMAGE_HEIGHT,
      }
    },
    updateSize({ coordinates }) {
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    aspectRatioCustom() {
      return process.env.VUE_APP_EVENT_IMAGE_RATIO
    },

    addImage() {
      if (event) event.preventDefault()
      const reader = new FileReader()
      let file = this.$refs.refInputEl.files[0]

      if (file) {
        reader.readAsDataURL(file)
        this.imageName = file.name
        reader.onload = (evt) => {
          let base64 = evt.target.result
          this.posterImagePreview = base64
          this.$bvModal.show('bv-modal-example')
        }
      }
    },
    uploadPosterImage() {
      const { canvas } = this.$refs.cropper.getResult()
      const formData = new FormData()
      canvas.toBlob(async blob => {
        formData.append(
            'files',
            blob,
            this.imageName
        )

        let url = `videosnippets/uploadposter/${this.$route.params.id}`
        this.$http
            .post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {

              this.currentItem.poster = response.data.poster
              this.$bvModal.hide('bv-modal-example')
              this.$bvToast.toast(this.$i18n.t('Image uploaded successfully'), {
                title: this.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
              })
            })
            .catch((e) => {
              this.$bvToast.toast(this.$i18n.t('Image not uploaded'), {
                title: this.$i18n.t('Error'),
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              })
            })
      })
    },
    deletePosterImage() {
      let url = `videosnippets/removeposter/${this.$route.params.id}`

      this.$http
          .delete(url)
          .then((response) => {
            this.currentItem.poster = null

            this.$bvToast.toast(this.$i18n.t('Image deleted'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Image not deleted'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },

    deleteItem() {
      this.helperDeleteItem(`/videosnippets/${this.$route.params.id}`, null, 'apps-videosnippets-list')
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    resetForm() {
    },
    onSubmit() {
      this.updateItem()
    },

    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}

            if (success) {
              this.$http.put(`/videosnippets/${this.$route.params.id}`, this.currentItem)
                  .then(res => {
                    this.$bvToast.toast(this.$i18n.t('Item updated successfully'), {
                      title: this.$i18n.t('Success'),
                      variant: 'success',
                      toaster: 'b-toaster-bottom-right',
                    })
                  })
                  .catch(error => {
                    if (error.response.status == 422) {
                      this.validation = error.response.data.errors
                      this.validate = true
                    } else {
                      this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                        title: this.$i18n.t(error.response.data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-bottom-right',
                      })
                    }

                  })
            }
          })
    },

    getUploadUrl() {
      return `videosnippets/fileupload/${this.$route.params.id}`
    },

    getFiles() {
      console.debug(this.currentItem.media)
      return this.currentItem.media
    },

    getClients() {
      let data = this.helperGet(`/me`, this.setClients)
    },

    setClients(data) {
      this.me = data
    }
  },

  mounted() {
    this.getClients()
    this.getItem(`/videosnippets/${this.$route.params.id}`)

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.chunk-loading {
  margin: -12px;
  display: flex;
  width: calc(100% + 24px);
}

.chunk-loading .chunk-loading-part {
  height: 25px;
  line-height: 25px;
  flex: 1;
  background: #ccc;
  font-size: 14px;
  color: white;
  text-align: center;
}

.chunk-loading .chunk-loading-part.chunk-loading-part__uploaded {
  background: #28A745;
}

.quill-editor .ql-container {
  height: 250px
}

.fileuploader-theme-boxafter .fileuploader-input h3 a {
  color: rgb(164 126 78);
}

[dir] .file-type-video .fileuploader-item-icon {
  background-color: rgb(164 126 78) !important;
}

[dir=ltr] .fileuploader-input .fileuploader-input-button {
  background: rgb(164 126 78) !important;
}

.poster_actions_buttons {
  background: #a47e4e !important;
  border: none;
}

.b-avatar .b-avatar-img img {
  object-fit: contain;
  background: #fff;
}

.vue-advanced-cropper__background {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
}

.preview-result-example {
  display: flex;

  &__cropper {
    width: 300px;
  }

  &__previews {
    margin-left: 32px;
  }

  &__preview {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    border: 1px solid #c4c3c3;

    &--small {
      width: 60px;
      height: 60px;
    }
  }

  &__preview-image {
    width: 100%;
  }

  &__button {
    position: absolute;
    left: 16px;
    bottom: 0;
  }
}

.vue-rectangle-stencil {
  background: #ccc;
}

.vue-advanced-cropper__background {
  //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  background: #DDD;
}

.vertical-buttons {
  position: initial;
  left: 2px;
  top: 80%;
  transform: translateY(-50%);
}
.square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}

.zoom-button {
  display: inline;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  //margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}

.zoom-group {
  display: flex;
}

</style>
