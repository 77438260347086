<template>
  <Fileuploader name="files" limit="1" @options="getFileuploaderOptions"></Fileuploader>
</template>
<script>
import Fileuploader from '@/views/extensions/fileuploader/Fileuploader.vue'
import "material-design-iconic-font/dist/css/material-design-iconic-font.css";

export default {
  components: {
    Fileuploader
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    getFileuploaderOptions(cmp) {
      var name = cmp.name;
      const SELF = this;

      cmp.options = {
        limit: 1,
        changeInput: ' ',
        theme: 'boxafter',
        enableApi: true,
        files: this.$parent.currentItem.media,
        chunk: true,
        extensions: ['video/*'],
        thumbnails: {
          box: '<div class="fileuploader-input">' +
              '<div class="fileuploader-input-inner">' +
              '<h3>${captions.feedback} ${captions.or} <a>${captions.button}</a></h3>' +
              '</div>' +
              '<button type="button" class="fileuploader-input-button"><i class="zmdi zmdi-plus"></i></button>' +
              '</div>' +
              '<div class="fileuploader-items">' +
              '<ul class="fileuploader-items-list"></ul>' +
              '</div>',
          item: '<li class="fileuploader-item">' +
              '<div class="fileuploader-item-wrapper">' +
              '<div class="columns">' +
              '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
              '<div class="column-title">' +
              '<div title="${name}">${name}</div>' +
              '<span>${size2}</span>' +
              '</div>' +
              '<div class="column-actions">' +
              '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
              '</div>' +
              '${progressBar}' +
              '</div>' +
              '</div>' +
              '<div class="input-title">' +
              '<div class="input-group mt-1 d-none">' +
              '    <input type="text" class="form-control" value="" placeholder="Datei-Titel">' +
              '<div class="input-group-append">' +
              '    <button class="btn btn-success btn-save-title" type="button" data-updateurl=""><i class="zmdi zmdi-save"></i></button>' +
              '  </div>' +
              '</div>' +
              '</div>' +
              '</li>',
          item2: '<li class="fileuploader-item">' +
              '<div class="fileuploader-item-wrapper">' +
              '<div class="columns">' +
              '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
              '<div class="column-title">' +
              '<a href="${data.fileurl}" target="_blank">' +
              '<div title="${name}">${name}</div>' +
              '<span>${size2} - ${duration}</span>' +
              '</a>' +
              '</div>' +
              '<div class="column-actions">' +
              '<a href="${data.fileurl}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="zmdi zmdi-download"></i></a>' +
              '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></a>' +
              '</div>' +
              '</div>' +
              '<div class="input-title">' +
              '<div class="input-group mt-2 d-none">\n' +
              '    <input type="text" class="form-control" id="file-title-${data.id}" placeholder="Datei-Titel" value="${data.title}">' +
              '<div class="input-group-append">' +
              '    <button class="btn btn-success btn-save-title" data-updateurl="${data.url}" type="button"><i class="zmdi zmdi-save"></i></button>' +
              '  </div>' +
              '</div>' +
              '</div>' +
              '</div>' +
              '</li>',
          onItemShow: function (item) {
            // add sorter button to the item html
            item.html.find('.fileuploader-action-remove i').attr('class', 'zmdi zmdi-close');
            item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Eintrag verschieben"><i class="zmdi zmdi-arrows"></i></button>');
          }
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
          var api = jQuery.fileuploader.getInstance(inputEl);

          var plusInput = parentEl.find('.fileuploader-input'),
              api = jQuery.fileuploader.getInstance(inputEl.get(0));


          plusInput.on('click', function () {
            api.open();
          });

          api.getOptions().dragDrop.container = plusInput;
        },
        upload: {
          url: this.$http.defaults.baseURL + this.$parent.getUploadUrl(),
          type: 'POST',
          beforeSend: function(item, listEl, parentEl, newInputEl, inputEl) {
            // here you can create upload headers
            item.upload.headers = {
              "Authorization": "Bearer " + localStorage.getItem('accessToken')
            };

            return true;
          },
          enctype: 'multipart/form-data',
          start: true,
          synchron: true,
          onSuccess: function(result, item) {
            var data = {};

            if (result && result.files)
              data = result;
            else
              data.hasWarnings = true;

            // if success
            if (data.files.isSuccess && data.files.files[0]) {
              item.name = data.files.files[0].name;
              item.file_id =  data.files.files[0].data.id;
              item.data.id = data.files.files[0].data.id;
              item.data.url = data.files.files[0].data.url;
              item.html.find('.column-title > div:first-child').text(data.files.files[0].name).attr('title', data.files.files[0].name);
              item.html.find('.btn-save-title').data('updateurl', data.files.files[0].data.url);
            }

            // if warnings
            if (data.hasWarnings) {
              for (var warning in data.warnings) {
                alert(data.warnings[warning]);
              }

              item.html.removeClass('upload-successful').addClass('upload-failed');
              return this.onError ? this.onError(item) : null;
            }

            item.html.find('.fileuploader-action-remove');
            item.html.find("input").attr('id', item.html.find("input").attr('id') + item.data.id);

            item.html.find('.column-title span').html(item.size2);
            setTimeout(function() {
              item.progressBar.fadeOut(400);
              SELF.$bvToast.toast(SELF.$i18n.t('File uploaded successfully'), {
                title: SELF.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right'
              })
            }, 400);
          },
          onError: function(item) {
            if (item.progressBar) {
              item.html.find('.column-title span').html(item.size2);
              item.progressBar.hide().find('.bar').width(0);
            }

            item.upload.status != 'cancelled' && item.html.find('.fileuploader-action-retry').length == 0 ? item.html.find('.column-actions').prepend(
                    '<button type="button" class="fileuploader-action fileuploader-action-retry" title="Retry"><i class="fileuploader-icon-retry"></i></button>'
            ) : null;
          },
          onProgress: function(data, item) {
            item.html.find('.column-title span').html(data.percentage == 99 ? 'Uploading...' : data.loadedInFormat + ' / ' + data.totalInFormat);
            item.progressBar.show().find('.bar').width(data.percentage + "%");
          },
          onComplete: null,
        },
        onRemove: function (item) {
          SELF.$http.delete(`/videosnippets/fileremove/${SELF.$route.params.id}`)
          SELF.$parent.currentItem.media = null;
          SELF.$parent.currentItem.media_id = null;
        },
        captions: jQuery.extend(true, {}, jQuery.fn.fileuploader.languages['de'], {
          feedback: 'Dateien hierherziehen',
          or: 'oder',
          button: 'Datei auswählen',
        }),
        dialogs: {
          // alert dialog
          alert: function(text) {
            return alert(text);
          },

          // confirm dialog
          confirm: function(text, callback) {
            SELF.$swal({
              title: SELF.$i18n.t('Are you sure?'),
              text: SELF.$i18n.t("You won't be able to revert this!"),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: SELF.$i18n.t('Yes, delete it!'),
              cancelButtonText: SELF.$i18n.t('Cancel'),
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              callback(function() {
                alert("deleted")
              })
            })
          }
        }
      };
    }
  }
}
</script>
